import type { FieldValues, Path } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import { ColorPickerV2 } from "@circle-react-shared/ColorPickerV2";

interface FormColorPickerProps<T extends FieldValues> {
  name: Path<T>;
  shouldShowPalette?: boolean;
}

export const FormColorPickerV2 = <T extends FieldValues>({
  name,
  shouldShowPalette = true,
  ...passThroughProps
}: FormColorPickerProps<T>) => {
  const { control } = useFormContext<T>();

  return (
    <Controller<T>
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ColorPickerV2
          {...passThroughProps}
          color={value}
          shouldShowPalette={shouldShowPalette}
          onColorChange={({ hex }) => onChange(hex)}
        />
      )}
    />
  );
};
