import type { ComponentPropsWithoutRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import type { FieldValues, Path } from "react-hook-form";
import { SliderWithInput } from "../../../SliderWithInput";

interface SliderControllerProps<
  T extends string = string,
  TFieldValues extends FieldValues = FieldValues,
> extends Omit<
    ComponentPropsWithoutRef<typeof SliderWithInput<T>>,
    "value" | "onChange"
  > {
  name: Path<TFieldValues>;
}

export const FormSliderWithInput = <
  T extends string = string,
  TFieldValues extends FieldValues = FieldValues,
>({
  name,

  ...sliderProps
}: Readonly<SliderControllerProps<T, TFieldValues>>) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <SliderWithInput
          {...sliderProps}
          value={value?.value ?? value}
          onChange={(newValue, unit) => {
            if (unit) {
              onChange({ value: newValue, unit });
            } else {
              onChange(newValue);
            }
          }}
        />
      )}
    />
  );
};
